// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import lazyLoadInstance from './plugins/lazyload';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function() {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function() {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function() {
  //Photos Template Download in The Top
  if ($('.photos-gallery-container').length) {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
    setTimeout(function() {
      $(window).scrollTop(0);
    }, 0);
  }

  $(document).on('click', '.more-link', function() {
    let $this = $(this);
    $this.toggleClass('active');
    if ($this.hasClass('active')) {
      $this.prev('.extended').addClass('active');
      $this.find('span').text($this.data('less'));
    } else {
      $this.prev('.extended').removeClass('active');
      $this.find('span').text($this.data('more'));
    }
  });
  /**
   * Photos Page load More
   */
  // $(document).on('click', '.load-more', function() {
  //   let $this = $(this);
  //   let $activeBlock = $('.photos-block.active'),
  //     $nextBlock = $activeBlock.next('.photos-block');
  //   if ($nextBlock.length) {
  //     $nextBlock.addClass('active');
  //   }
  //   let $activeBlocks = $('.photos-block.active');
  //   if ($activeBlocks.length === $('.photos-block').length) {
  //     $this.hide();
  //   }
  // });

  /**
   * Photos Page load More
   */
  $(window).on('scroll', function() {
    let $container = $('.photos-gallery-container');
    if ($container.length) {
      let scrollTop = $(window).scrollTop();
      let windowHeight = $(window).height();
      let containerOffset = $container.offset().top;
      let containerHeight = $container.outerHeight();

      if (scrollTop + windowHeight >= containerOffset + containerHeight) {
        let $nextBlock = $('.photos-block:not(.active)').first();
        if ($nextBlock.length) {
          $nextBlock.addClass('active');
        }
      }
    }
  });

  /**
   * Rooms sliders
   */
  $('.rooms-slider').each(function() {
    $(this).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      fade: true,
      asNavFor: '.rooms-vertical-slider',
      customPaging: function(slider, i) {
        return (
          `<span class='slick-dot'>${(i + 1)} / <span>${slider.slideCount}</span></span>`
        );
      },
      nextArrow: '<button class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16.444" viewBox="0 0 24 16.444"><path d="M21.761,7.793,14.21,1.186l.711-.812L24,8.318l-9.066,8.5L14.2,16.03l7.636-7.159H0V7.793Z" transform="translate(0 -0.374)" fill="#567ca4" fill-rule="evenodd"/></svg></button>',
      prevArrow: '<button class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="16.444" viewBox="0 0 24 16.444"><path d="M2.239,7.793,9.79,1.186,9.079.374,0,8.318l9.066,8.5L9.8,16.03,2.168,8.872H24V7.793Z" transform="translate(0 -0.374)" fill="#567ca4" fill-rule="evenodd"/></svg></button>',
    });
  });

  $('.rooms-vertical-slider').each(function() {
    $(this).slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: '.rooms-slider',
      dots: false,
      vertical: true,
      verticalSwiping: true,
      arrows: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            vertical: false,
            verticalSwiping: false,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            vertical: false,
            verticalSwiping: false,
          },
        },
      ],
    }).on('afterChange', (e, slick, currentSlide) => {
      let current_slide = $(slick.$slides.get(currentSlide));

      if (current_slide.next().hasClass('slick-cloned'))
        lazyLoadInstance.update();
    });
  });

  /**
   * Homepage room slider
   */
  $('.home-rooms__slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    focusOnSelect: true,
    infinite: false,
    nextArrow: '<button class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56"><g transform="translate(-1535 10)"><path d="M12,1A11,11,0,0,0,1,12V44A11,11,0,0,0,12,55H44A11,11,0,0,0,55,44V12A11,11,0,0,0,44,1H12m0-1H44A12,12,0,0,1,56,12V44A12,12,0,0,1,44,56H12A12,12,0,0,1,0,44V12A12,12,0,0,1,12,0Z" transform="translate(1535 -10)" fill="#567ca4"/><path d="M21.761,7.793,14.21,1.186l.711-.812L24,8.318l-9.066,8.5L14.2,16.03l7.636-7.159H0V7.793Z" transform="translate(1551 9.626)" fill="#567ca4" fill-rule="evenodd"/></g></svg></button>',
    prevArrow: '<button class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56"><g transform="translate(-1535 10)"><path d="M12,1A11,11,0,0,0,1,12V44A11,11,0,0,0,12,55H44A11,11,0,0,0,55,44V12A11,11,0,0,0,44,1H12m0-1H44A12,12,0,0,1,56,12V44A12,12,0,0,1,44,56H12A12,12,0,0,1,0,44V12A12,12,0,0,1,12,0Z" transform="translate(1535 -10)" fill="#567ca4"/><path d="M21.761,7.793,14.21,1.186l.711-.812L24,8.318l-9.066,8.5L14.2,16.03l7.636-7.159H0V7.793Z" transform="translate(1575 26.818) rotate(180)" fill="#567ca4" fill-rule="evenodd"/></g></svg></button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]',
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function(event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  //smooth scroll to section if page url has hash
  if (window.location.hash) {
    let hash = window.location.hash,
      $target = $(hash);
    if ($target.length) $('html, body').animate({ scrollTop: $target.offset().top }, 500);
  }

  $('.header-menu a[href="#"]').on('click', function(e) {
    let $this = $(this);
    if (window.innerWidth < 1024 && $this.next('.submenu-toggle').length) {
      e.preventDefault();
      $this.next('.submenu-toggle').trigger('click');
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function() {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    },
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function() {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function() {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function() {
    setTimeout(function() {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function() {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function() {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function() {
  // jQuery code goes here
});
